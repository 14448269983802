<el-scrollbar ref="scrollbar" class="height-table" v-loading="isLoading">
  <el-collapse v-model="activeNames" class="width-search" accordion>
    <el-collapse-item title="Tìm kiếm" name="1">
      <template #title>
          <el-button
            type="success"
            @click.stop.prevent="exportCSV"
            size="mini"
            icon="el-icon-download"
            v-if="csv_processing_state == 'ready'"
          >
          Tải Tệp
          </el-button>
          <el-button
            v-if="csv_processing_state && (csv_processing_state.indexOf('%') != -1 || csv_processing_state.indexOf('pending') != -1)"
            type="warning"
            size="mini"
            icon="el-icon-loading"
          >
          <span v-if="csv_processing_state == 'pending'">Hàng chờ</span>
          <span v-else>Đang xử lý - {{ csv_processing_state }}</span>
          </el-button>
          <el-button
            v-else
            type="primary"
            @click.stop.prevent="requestExportCSV"
            size="mini"
            icon="el-icon-top-right"
            :disabled="!isRecordsExportable"
          >
          Xuất Excel
          </el-button>
            <el-button @click.stop.prevent="deleteAllSearch()" size="mini" icon="el-icon-close">
              Xóa tất cả
            </el-button>
            <el-button
              type="primary"
              :disabled="is_disable_search"
              @click.stop.prevent="fetchSearch()"
              size="mini"
              icon="el-icon-search"
            >
              Tìm kiếm
            </el-button>
          <span class="ml-3">Bộ lọc</span>
      </template>
      <el-form :model="multipleSearch" class="px-4" label-position="right">
        <el-row :gutter="24">
          <el-col :xs="24" :sm="12" :lg="8" :class="{'bg-gray-100': isProductSearch}">
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.seller_name}"
              @click="changeStatusColumn($event)"
              label="Bên bán/mua"
              label-width="110px"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.seller_name"
                placeholder="Tên bên bán hoặc mua"
              />
            </el-form-item>

            <!-- <el-form-item -->
            <!--   label="Mẫu số" -->
            <!--   label-width="90px" -->
            <!--   class="w-full lable-el" -->
            <!--   :class="{ 'lable-hidden-column': !columnStatus.form}" -->
            <!--   @click="changeStatusColumn($event)" -->
            <!-- > -->
            <!--   <el-input -->
            <!--     clearable -->
            <!--     @keyup.enter="fetchSearch()" -->
            <!--     v-model="multipleSearch.form" -->
            <!--     placeholder="Mẫu số" -->
            <!--   /> -->
            <!-- </el-form-item> -->
            <!-- <el-form-item -->
            <!--   label="Ký hiệu" -->
            <!--   class="w-full lable-el" -->
            <!--   :class="{ 'lable-hidden-column': !columnStatus.serial}" -->
            <!--   @click="changeStatusColumn($event)" -->
            <!--   label-width="90px" -->
            <!-- > -->
            <!--   <el-input -->
            <!--     clearable -->
            <!--     @keyup.enter="fetchSearch()" -->
            <!--     v-model="multipleSearch.serial" -->
            <!--     placeholder="Ký hiệu" -->
            <!--   /> -->
            <!-- </el-form-item> -->

            <el-form-item
              prop="number"
              :rules="[{ validator: checkInvoiceNumber }]"
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.number}"
              @click="changeStatusColumn($event)"
              label="Số hóa đơn"
              label-width="110px"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.number"
                placeholder="Số hóa đơn"
              />
            </el-form-item>
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.issued_date}"
              @click="changeStatusColumn($event)"
              label="Ngày PH"
              label-width="110px"
            >
              <el-date-picker
                v-model="issued_date"
                type="daterange"
                start-placeholder="Ngày bắt đầu"
                end-placeholder="Ngày kết thúc"
                format="DD/MM/YYYY"
                :disabled-date="disabledDate"
                @change="handleRequestSyncDate"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24" :sm="12" :lg="8" :class="{'bg-gray-100': isProductSearch}">
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.seller_tax_code}"
              @click="changeStatusColumn($event)"
              label="MST bán"
              label-width="110px"
              prop="seller_tax_code"
              :rules="[{ validator: checkMST }]"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.seller_tax_code"
                placeholder="Mã số thuế bên bán"
              />
            </el-form-item>

            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.buyer_tax_code}"
              @click="changeStatusColumn($event)"
              label="MST mua"
              label-width="110px"
              prop="buyer_tax_code"
              :rules="[{ validator: checkMST }]"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.buyer_tax_code"
                placeholder="Mã số thuế bên mua"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4" :xs="24" :sm="12" :lg="8">
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.description}"
              @click="changeStatusColumn($event)"
              label="Tên HH,DV"
              label-width="110px"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.description"
                placeholder="Tên hàng hóa, dịch vụ"
              />
            </el-form-item>

            <el-form-item
              label="Tiền tệ"
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.currency}"
              @click="changeStatusColumn($event)"
              label-width="110px"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.currency"
                placeholder="Tiền tệ"
                :disabled="isProductSearch"
              />
            </el-form-item>

            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.vat}"
              @click="changeStatusColumn($event)"
              label="Thuế suất"
              label-width="110px"
            >
              <el-select
                class="w-full select-vat"
                v-model="vat"
                placeholder="Thuế suất"
                clearable
                @keyup.enter="fetchSearch()"
              >
                <!-- <el-option value="KCT">KCT</el-option> -->
                <el-option value="0%">0%</el-option>
                <el-option value="5%">5%</el-option>
                <el-option value="8%">8%</el-option>
                <el-option value="10%">10% </el-option>
              </el-select>
            </el-form-item>

          </el-col>

          <el-col :span="4" :xs="24" :sm="12" :lg="4" v-if="false">
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.description}"
              @click="changeStatusColumn($event)"
              label="Tên HH,DV"
              label-width="90px"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.description"
                placeholder="Tên hàng hóa, dịch vụ"
              />
            </el-form-item>
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.unit}"
              @click="changeStatusColumn($event)"
              label="Đơn vị tính"
              label-width="90px"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.unit"
                placeholder="Đơn vị tính"
              />
            </el-form-item>
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.quantity}"
              @click="changeStatusColumn($event)"
              label="Số lượng"
              label-width="90px"
            >
              <el-input
                clearable
                @keyup.enter="fetchSearch()"
                v-model="multipleSearch.quantity"
                placeholder="Số lượng"
              />
            </el-form-item>

          </el-col>

          <el-col :span="4" :xs="24" :sm="12" :lg="8" v-if="false">
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.unit_price}"
              @click="changeStatusColumn($event)"
              label="Đơn giá"
              label-width="90px"
            >
              <div class="flex justify-center items-center">
                <div class="w-full">
                  <input-money
                    v-model="unit_price.start"
                    class="money"
                    :invoiceStyle="false"
                    :negative="true"
                    :decima="0"
                    @inputChange="unit_price.start = $event"
                    @keyup.enter="fetchSearch()"
                  />
                </div>
                <div class="px-3">-</div>
                <div class="w-full">
                  <input-money
                    v-model="unit_price.end"
                    class="money"
                    :invoiceStyle="false"
                    :negative="true"
                    :decima="0"
                    @inputChange="unit_price.end = $event"
                    @keyup.enter="fetchSearch()"
                  />
                </div>
              </div>
              <div
                v-if="errorPrice.is_unit_price"
                class="m-0 leading-4 text-red-400"
              >
                Giá trị đầu phải nhỏ hơn giá trị cuối
              </div>
            </el-form-item>
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.amount}"
              @click="changeStatusColumn($event)"
              label="Thành tiền"
              label-width="90px"
            >
              <div class="flex justify-center items-center">
                <div class="w-full">
                  <input-money
                    v-model="amount.start"
                    class="money"
                    :invoiceStyle="false"
                    :negative="true"
                    :decima="0"
                    @inputChange="amount.start = $event"
                    @keyup.enter="fetchSearch()"
                  />
                </div>
                <div class="px-3">-</div>
                <div class="w-full">
                  <input-money
                    v-model="amount.end"
                    class="money"
                    :invoiceStyle="false"
                    :negative="true"
                    :decima="0"
                    @inputChange="amount.end = $event"
                    @keyup.enter="fetchSearch()"
                  />
                </div>
              </div>
              <div
                v-if="errorPrice.is_amount"
                class="m-0 leading-4 text-red-400"
              >
                Giá trị đầu phải nhỏ hơn giá trị cuối
              </div>
            </el-form-item>
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.vat_amount}"
              @click="changeStatusColumn($event)"
              label="Tiền thuế"
              label-width="90px"
            >
              <div class="flex justify-center items-center">
                <div class="w-full">
                  <input-money
                    v-model="vat_amount.start"
                    class="money"
                    :invoiceStyle="false"
                    :negative="true"
                    :decima="0"
                    @inputChange="vat_amount.start = $event"
                    @keyup.enter="fetchSearch()"
                  />
                </div>
                <div class="px-3">-</div>
                <div class="w-full">
                  <input-money
                    v-model="vat_amount.end"
                    class="money"
                    :invoiceStyle="false"
                    :negative="true"
                    :decima="0"
                    @inputChange="vat_amount.end = $event"
                    @keyup.enter="fetchSearch()"
                  />
                </div>
              </div>
              <div
                v-if="errorPrice.is_vat_amount"
                class="m-0 leading-4 text-red-400"
              >
                Giá trị đầu phải nhỏ hơn giá trị cuối
              </div>
            </el-form-item>
            <el-form-item
              class="w-full lable-el"
              :class="{ 'lable-hidden-column': !columnStatus.amount_included_vat}"
              @click="changeStatusColumn($event)"
              label="Tổng tiền"
              label-width="90px"
            >
              <div class="flex justify-center items-center">
                <div class="w-full">
                  <input-money
                    v-model="amount_included_vat.start"
                    class="money"
                    :invoiceStyle="false"
                    :negative="true"
                    :decima="0"
                    @inputChange="amount_included_vat.start = $event"
                    @keyup.enter="fetchSearch()"
                  />
                </div>
                <div class="px-3">-</div>
                <div class="w-full">
                  <input-money
                    v-model="amount_included_vat.end"
                    class="money"
                    :invoiceStyle="false"
                    :negative="true"
                    :decima="0"
                    @inputChange="amount_included_vat.end = $event"
                    @keyup.enter="fetchSearch()"
                  />
                </div>
              </div>
              <div
                v-if="errorPrice.is_amount_included_vat"
                class="m-0 leading-4 text-red-400"
              >
                Giá trị đầu phải nhỏ hơn giá trị cuối
              </div>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </el-collapse-item>
  </el-collapse>

  <div class="pr-3 bg-white">
    <el-table
      :data="dataInvoices"
      border
      stripe
      style="width: 100%"
      empty-text="Không có dữ liệu"
      :header-cell-style="{ background: 'white' }"
      @sort-change="sortChange"
      @row-click="clickRow($event)"
    >
      <el-table-column
        type="index"
        :index="indexMethod"
        label="STT"
        width="40"
        align="center"
      />

      <el-table-column
        prop="form"
        v-if="columnStatus.form"
        label="Mẫu số"
        align="center"
        width="40"
      />

      <el-table-column
        prop="serial"
        v-if="columnStatus.serial"
        label="Ký hiệu"
        align="center"
        width="70"
      />

      <el-table-column
        prop="number"
        v-if="columnStatus.number"
        label="Số HĐ"
        align="center"
        width="70"
      />

      <el-table-column
        prop="issued_date"
        v-if="columnStatus.issued_date"
        label="Ngày PH"
        align="center"
        width="80"
      >
        <template #default="props">
          <div>{{ formatDate(props.row.issued_date) }}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="seller_name"
        v-if="columnStatus.seller_name"
        label="Bên bán hàng"
        width="200"
      >
        <template #default="props">
          <div class="mb-2">{{ props.row.seller_name }}</div>
          <div>{{ props.row.seller_tax_code }}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="buyer_name"
        v-if="columnStatus.buyer_name"
        label="Bên mua hàng"
        width="200"
      >
        <template #default="props">
          <div class="mb-2">{{ props.row.buyer_name }}</div>
          <div>{{ props.row.buyer_tax_code }}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="product_names"
        v-if="columnStatus.description"
        label="Tên hàng hóa, dịch vụ"
      >
        <template #default="props">
          <el-tooltip
            class="item"
            effect="dark"
            :content="props.row.product_names"
            placement="right"
          >
            <div class="line-clamp-4 w-full">{{ props.row.product_names }}</div>

            <template #content>
              <div :class="{'w-96': props.row.product_names}" v-html="props.row.product_names"></div>
            </template>
          </el-tooltip>
        </template>
      </el-table-column>

      <!-- <el-table-column -->
      <!--   prop="unit" -->
      <!--   v-if="columnStatus.unit" -->
      <!--   label="ĐVT" -->
      <!--   align="center" -->
      <!--   min-width="60" -->
      <!-- /> -->

      <!-- <el-table-column -->
      <!--   prop="quantity" -->
      <!--   v-if="columnStatus.quantity" -->
      <!--   label="SL" -->
      <!--   align="center" -->
      <!--   width="40" -->
      <!-- >  -->
      <!-- <template #default="props"> -->
      <!--   <div>{{ formatNumber(props.row.quantity) }}</div> -->
      <!-- </template> -->
      <!-- </el-table-column> -->

      <el-table-column
        prop="currency"
        v-if="columnStatus.currency"
        label="Tiền tệ"
        align="center"
        width="60"
      />
      <!-- <el-table-column -->
      <!--   prop="unit_price" -->
      <!--   v-if="columnStatus.unit_price" -->
      <!--   label="Đơn giá" -->
      <!--   align="right" -->
      <!--   min-width="110" -->
      <!-- > -->
      <!--   <template #default="props"> -->
      <!--     <div class="money-row"> -->
      <!--       <input-money -->
      <!--         v-model.lazy="props.row.unit_price" -->
      <!--         class="money" -->
      <!--         :invoiceStyle="true" -->
      <!--         :table="true" -->
      <!--         :decima="0" -->
      <!--       /> -->
      <!--     </div> -->
      <!--   </template> -->
      <!-- </el-table-column> -->

      <el-table-column
        prop="amount"
        v-if="columnStatus.amount"
        label="Thành tiền"
        align="right"
        width="110"
      >
        <template #default="props">
          <div class="money-row">
            <input-money
              v-model.lazy="props.row.amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="vat"
        v-if="columnStatus.vat"
        label="TS"
        align="center"
        width="60"
      >
        <template #default="props">
          <div v-if="props.row.is_no_tax" class="font-medium">KCT</div>
          <div v-else class="money-row">{{props.row.vat}}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="vat_amount"
        v-if="columnStatus.vat_amount"
        label="Tiền thuế"
        align="right"
        width="110"
      >
        <template #default="props">
          <div class="money-row">
            <input-money
              v-model.lazy="props.row.vat_amount"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="amount_included_vat"
        v-if="columnStatus.amount_included_vat"
        label="Tổng tiền"
        align="right"
        width="110"
      >
        <template #default="props">
          <div class="money-row">
            <input-money
              v-model.lazy="props.row.amount_included_vat"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="seller_status"
        label="TT NCC"
        align="center"
        width="100"
      >
        <template #default="prop">
          <Status :status="prop.row.seller_status" placement="left" />
        </template>
      </el-table-column>
      <el-table-column
        prop="lookupinfo__invoice_status"
        label="TT HD"
        align="center"
        width="100"
      >
        <template #default="prop">
          <InvoiceStatus :lookupinfo="prop.row.lookupinfo" placement="left" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</el-scrollbar>

<div class="paginative text-right mt-2" v-if="dataInvoices.length">
  <cursor-paginate
    :currentPage="paginate.currentPage"
    :limit="paginate.limit"
    :next="paginate.next"
    :prev="paginate.prev"
    @update:limit="handleLimitChange"
    @update:currentPage="handleCurrentPageChange($event)"
    @update:cursor="handleCursorChange($event)"
  />
</div>
